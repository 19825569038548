import * as React from 'react'
import Loading from '@/components/screens/Loading'
import useProgressBar from '@/hooks/useProgressBar'
import { isBrowser } from '@/utils/'

const LOADING = 'LOADING'
const SUCCESS = 'SUCCESS'

function withClientOnly(Component) {
	function ClientOnlyWrapper(props) {
		const { setLoading } = useProgressBar(true)
		const [view, setView] = React.useState(LOADING)

		React.useEffect(() => {
			if (isBrowser && view === LOADING) {
				setLoading(false)
				setView(SUCCESS)
			}
		}, [setLoading, view])

		return view === LOADING ? (
			<Loading case="loading">Loading profile</Loading>
		) : (
			<Component case="browser" {...props} />
		)
	}

	return ClientOnlyWrapper
}

export default withClientOnly
