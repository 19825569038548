import * as React from 'react'
import Link from '@/utils/Link'
import { string, shape } from 'prop-types'
import Text from '@/utils/Text'
import Center from '@/utils/Center'
import Button from '@/components/buttons/Button'

function PageNotFound({
	title = 'Page not found',
	message = 'Oops! The page you are looking for has been removed or relocated.',
	goto = { href: '/', text: 'Back to Home' }
}) {
	return (
		<Center className="h-full" data-testid="404">
			<div className="mx-auto max-w-login py-3xl">
				<Text as="h2" className="text-2xl mb-lg font-h-light">
					{title}
				</Text>
				<Text className="mb-xl">{message}</Text>
				<Button
					as={Link}
					to={goto.href}
					className="flex-grow w-full mr-md px-xl md:w-auto md:px-2xl"
				>
					{goto.text}
				</Button>
			</div>
		</Center>
	)
}

PageNotFound.propTypes = {
	message: string,
	title: string,
	goto: shape({
		href: string,
		text: string
	})
}

export default PageNotFound
