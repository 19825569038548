import * as React from 'react'
import Layout from '@/container/Layout'
import PageNotFound from '@/components/screens/PageNotFound'
import withClientOnly from '@/utils/withClientOnly'

function NotFoundTemplate() {
	return (
		<Layout>
			<PageNotFound />
		</Layout>
	)
}

export default withClientOnly(NotFoundTemplate)
